jQuery(document).ready(function () {

    // console.log('success');

    jQuery('.panelists-card .uncol .uncell').each(function(){
        
        var currentHeight = jQuery(this).height();
        var maxHeight = 0;
        if(currentHeight > maxHeight) {
          maxHeight = currentHeight;
          // alert(maxHeight);
        }

        jQuery('.panelists-card .uncol .uncell').height(maxHeight);

      });

    if (jQuery(window).width() > 768) {
      jQuery('body.page.panel .panelist-grid .isotope-wrapper .isotope-container .tmb .t-inside').each(function(){
        
        var currentHeight = jQuery(this).height();
        var maxHeight = 0;
        if(currentHeight > maxHeight) {
          maxHeight = currentHeight;
        }
  
        jQuery('body.page.panel .panelist-grid .isotope-wrapper .isotope-container .tmb .t-inside').height(maxHeight);
  
      });
    }
      
});

